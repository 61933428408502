import * as React from 'react'
import PropTypes from 'prop-types'
import parse from 'html-react-parser'
import joinClasses from '/src/functions/joinClasses'

import './styles.scss'

const SectionHeader = ({ variant, titleArea, className }) => {
    const HeadingTag = titleArea.headingLevel || 'h2'

    const classes = [
        'c-section-header',
        ...(variant ? [`c-section-header--${variant}`] : []),
        ...(className ? [className] : [])
    ]

    const concatenatedClasses = joinClasses(classes)

    return (
        <header className={concatenatedClasses}>
            {titleArea.title && <HeadingTag className="c-section-header__title">{parse(titleArea.title)}</HeadingTag>}

            {titleArea.description && <div className="c-section-header__subtext">{parse(titleArea.description)}</div>}
        </header>
    )
}

SectionHeader.propTypes = {
    /**
     * Specify a different style variant
     */
    variant: PropTypes.oneOf(['centered', 'inline']),
    titleArea: PropTypes.shape({
        headingLevel: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
        title: PropTypes.string,
        description: PropTypes.string
    }),
    /**
     * Optional CSS classes
     */
    className: PropTypes.string
}

SectionHeader.defaultProps = {
    titleArea: {
        headingLevel: 'h2',
        title: 'Section Title'
    }
}

export default SectionHeader
